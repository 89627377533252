import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobOrderService {
  constructor(private http: HttpClient) {}

  addJobOrder(jobOrderData: any): Observable<any> {
    return this.http.post<any>(environment.host + '/job_order.php', {
      gofor: 'addJobOrder',
      jobOrder: {
        ...jobOrderData,
      },
    });
  }

  viewJobOrder(jobOrderId: string): Observable<any> {
    return this.http.get<any>(environment.host + '/job_order.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'viewJobOrder',
          id: jobOrderId,
        },
      }),
    });
  }

  getJobOrder(jobOrderId: string): Observable<any> {
    return this.http.get<any>(environment.host + '/job_order.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'getJobOrder',
          id: jobOrderId,
        },
      }),
    });
  }

  updateJobOrder(jobOrderId: string, jobOrderData: any): Observable<any> {
    return this.http.put<any>(environment.host + '/job_order.php', {
      gofor: 'updateJobOrder',
      id: jobOrderId,
      jobOrder: {
        ...jobOrderData,
      },
    });
  }

  deleteJobOrder(jobOrderId: string): Observable<any> {
    return this.http.put<any>(environment.host + '/job_order.php', {
      gofor: 'deleteJobOrder',
      id: jobOrderId,
    });
  }

  getAllJobOrders(payload: any): Observable<any[]> {
    return this.http.post<any[]>(environment.host + '/job_order.php', {
      gofor: 'getAllJobOrders',
      ...payload,
    });
  }

  getJobOrderList(): Observable<any> {
    return this.http.post<any>(environment.host + '/job_order.php', {
      gofor: 'getJobOrderList',
    });
  }
}
