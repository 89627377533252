import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import _ from 'lodash';
import { catchError, concatMap, EMPTY, of } from 'rxjs';
import { JobOrderService } from '../service/api/job-order/job-order.service';
import { ToastrService } from 'ngx-toastr';

export interface jobordermodel {
  list: any[];
  item: any;
  age: string[];
}

export interface baseSelect {
  label: string;
  value: string;
}

export class FetchJobOrderList {
  static readonly type = '[JobOrderState] FetchJobOrderList';
  constructor(public id?: string) {}
}

export class CreateJobOrder {
  static readonly type = '[JobOrderState] CreateJobOrder';
  constructor(public payload: any) {}
}

export class FetchJobOrderById {
  static readonly type = '[JobOrderState] FetchJobOrderById';
  constructor(public id: any) {}
}

export class DeleteJobOrder {
  static readonly type = '[JobOrderState] DeleteJobOrder';
  constructor(public id: any) {}
}

@State<jobordermodel>({
  name: 'joborder',
  defaults: {
    list: [],
    item: null,
    age: _.range(18, 61).map((el) => el.toString()),
  },
})
@Injectable()
export class JobOrderState {
  constructor(
    private jobOrderService: JobOrderService,
    private toastService: ToastrService
  ) {}

  @Selector()
  static getList(state: jobordermodel): any[] {
    return state.list;
  }

  @Selector()
  static getViewList(state: jobordermodel): any[] {
    return state.item;
  }

  @Selector()
  static getAge(state: jobordermodel): baseSelect[] {
    return state.age.map((el) => {
      return {
        label: el,
        value: el,
      };
    });
  }

  @Action(CreateJobOrder)
  createJobOrder(states: StateContext<jobordermodel>, action: CreateJobOrder) {
    return this.jobOrderService.addJobOrder(action.payload).pipe(
      concatMap((res) => {
        console.log(res);
        this.toastService.success(res.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        return states.dispatch([new Navigate(['/', 'home', 'job-order'])]);
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  @Action(FetchJobOrderById)
  fetchJobOrderbyId(
    states: StateContext<jobordermodel>,
    action: FetchJobOrderById
  ) {
    return EMPTY;
  }

  @Action(DeleteJobOrder)
  deletClient(states: StateContext<jobordermodel>, action: DeleteJobOrder) {
    return this.jobOrderService.deleteJobOrder(action.id).pipe(
      concatMap((res) => {
        console.log(res);
        this.toastService.success(res.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        return states.dispatch([new Navigate(['/', 'home', 'job-order'])]);
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }
}
